
<template>
    <!-- mobile -->
    <template v-if="isMobile">
        <!-- card -->
        <div class="bg-white border" style="border-radius: 0.5rem;">
            <!-- header -->
            <div
                class="d-flex justify-content-between"
                @click="show = !show"
            >
                <div class="flex-grow-1 p-3">
                    <slot name="header">
                        <div class="fw-medium">{{ props.caption }}</div>
                    </slot>
                </div>
                <div class="flex-shrink-0 p-3">
                    <div :style="{ 'transition': 'all 500ms ease', 'transform': `rotate(${show ? 180 : 0}deg)` }">
                        <i class="bi bi-chevron-down" />
                    </div>
                </div>
            </div>
            <OCollapse v-model="show">
                <div class="p-2 pb-3 d-flex flex-column gap-2" style="margin-top: -0.75rem;">
                    <slot />
                </div>
            </OCollapse>
        </div>
    </template>

    <!-- desktop -->
    <template v-else>
        <div>
            <div class="d-flex justify-content-start">
                <div
                    class="p-2 w-100 d-flex align-items-center bg-white pb-1 fw-medium"
                    v-if="props.caption" 
                    @click="show = !show" 
                    style="cursor: pointer;"
                >
                    <div class="flex-grow-1">
                        <slot name="header">
                            <span class="me-2">{{props.caption}}</span>
                        </slot>
                    </div>
                    <!-- chevron to show collapse state, but it didn't look all that good on desktop -->
                    <template v-if="false">
                        <div class="flex-shrink-0 px-3">
                            <div :style="{ 'transition': 'all 500ms ease', 'transform': `rotate(${show ? 180 : 0}deg)` }">
                                <i class="bi bi-chevron-down" />
                            </div>
                        </div>
                    </template>
                </div>
                <slot name="links"></slot>
            </div>
            
            <OCollapse v-model="show">
                <div class="border-top" ref="propertiesItemWrapperRef">
                    <slot />
                </div>
            </OCollapse>
        </div>
    </template>
</template>

<script setup lang="ts">
import { ref, inject } from 'vue';
import { OCollapse } from "o365-ui-components";
import { InjectionKeys } from 'o365-utils';
import { isMobile } from "o365.GlobalState.ts";

export interface IProps {
    caption: string;
    collapsed?: any; // must be (any) to preserve undefined when prop is not provided
};

const props = defineProps<IProps>();

const propertiesItemWrapperRef = ref();

const sharedObj = inject(InjectionKeys.propertiesGridKey);
const propertiesGroup = sharedObj.value.usePropertiesGroup();

const show = ref<boolean>(isMobile.value ? (propertiesGroup.index === 0) : (props.collapsed !== true));
</script>
